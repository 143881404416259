body {
  height: 100%;
  background-color: #eee;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vp-title {
  font-size: 1.2em;
  font-weight: bold;
}

#nav-dropdown{
  color: white;
}

.a {
  text-decoration: none;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.form-label {
  padding-left: 10px;
  font-size: 0.85em;
  margin-bottom: -2px;
  margin-top: 15px;
  font-weight: bold;
}

.vp-input {
  outline: none;
  width: 100%;
  border-color: #aaa;
  border-radius: 10px;
  border-width: 1px;
  padding-left: 10px;
  font-size: 0.9em;
  background-color: #eee;
  height: 37px;
}

.vp-input:focus {
  border-color: #7DF04B;
  border-radius: 5px;
}

.vp-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #b5e800;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
